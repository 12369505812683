<template>
  <div>
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click='$router.go(-1)'>返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content bgFFF">
      <h2 class="title">
        <div class="title_icon">
        </div>
        基本信息
      </h2>
      <div class="attribute">
        <p>上报人：<span>{{ detailInfo.operatorName }}</span></p>
        <p>上报账号：<span>{{ detailInfo.operatorAccount }}</span></p>
      </div>
      <p style="margin-bottom: 20px">
      <h2 class="title">
        <div class="title_icon">
        </div>
        异常车辆信息
      </h2>
      <div class="Wrapper"
           style="float: left;width: 100%;">
        <el-form label-position="left"
                 label-width="100px"
                 :model="detailInfo"
                 :ref="'form'">
          <el-form-item label="车牌号："
                        style="float: left;width: 33%;"
                        prop='slaveRelation'>
            <span>{{ detailInfo.plateNumber }}</span>
          </el-form-item>
          <el-form-item
            label="异常类型："
            style="float: left;width: 33%;"
          >
            <span>{{ detailInfo.exceptDictName }}</span>
          </el-form-item>
          <el-form-item label="上报时间："
                        style="float: left;width: 33%;"
                        prop="label">
            <span>{{ detailInfo.reportTime }}</span>
          </el-form-item>
          <el-form-item label="道路名称："
                        style="float: left;width: 33%;"
                        prop="parkName">
            <span>{{ detailInfo.parkName }}</span>
          </el-form-item>
          <el-form-item label="入场时间："
                        style="float: left;width: 33%;"
                        prop='entryTime'>
            <span>{{ detailInfo.entryTime }}</span>
          </el-form-item>
          <div style="margin: 0 auto;width:100%; clear: both">
            <el-row :gutter="12"
                    style="margin-top: 20px;">
              <el-col :span="6" v-if="detailInfo.pictureUrl.length && detailInfo.pictureUrl[0]">
                 <el-card shadow="hover" :body-style="{ padding: '0px' }">
                <!-- mouseover -->
                <div class="img"><img :src='detailInfo.pictureUrl[0]'
                                      @click="showBigPic(detailInfo.pictureUrl[0])"
                                      style="width: 100%; height:180px"></div>
                 </el-card>
              </el-col>
              <el-col :span="6" v-if="detailInfo.pictureUrl.length && detailInfo.pictureUrl[1]">
                 <el-card shadow="hover" :body-style="{ padding: '0px' }">
                <!-- mouseover -->
                <div class="img"><img :src='detailInfo.pictureUrl[1]'
                       @click="showBigPic(detailInfo.pictureUrl[1])"
                       style="width: 100%; height:180px"></div>
                 </el-card>
              </el-col>
              <el-col :span="6" v-if="detailInfo.pictureUrl.length && detailInfo.pictureUrl[2]">
                 <el-card shadow="hover" :body-style="{ padding: '0px' }">
                <!-- mouseover -->
                <div class="img"><img :src='detailInfo.pictureUrl[2]'
                       @click="showBigPic(detailInfo.pictureUrl[2])"
                       style="width: 100%; height:180px"></div>
                 </el-card>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>
    <transition name="fade">
      <div class="mask"
           v-show="bigPic"
           :style="{'width': '500px','height': 'auto','margin': '0 auto','z-index': '10000', 'background-image': picUrl}"
           @click="bigPic=false">
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  filters: {
    carTypeFilter (val) {
      const result = ['小型车', '中型车', '大型车']
      return result[val]
    }
  },
  watch: {
    $route(to, from) {
      this.getDetailInfo()
    }
  },
  data () {
    return {
      picUrl: '',
      bigPic: false,
      detailInfo: {
        pictureUrl: []
      },
    }
  },
  methods: {
    // 获取详细信息
    getDetailInfo () {
      const exceptReportId = this.$route.query.exceptReportId
      const opt = {
        url: '/acb/2.0/except/detail',
        method: 'get',
        data: {
          exceptReportId,
        },
        success: res => {
          this.detailInfo = res.value
        }
      }
      this.$request(opt)
    },
    showBigPic (url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
  },
  created () {
    this.getDetailInfo()
  }
}
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper
  overflow hidden
.breadcrumb
  height 50px
.content

  overflow hidden
  border-radius 4px
  border 1px solid #d9d9d9
  padding 20px
.uploadPic
  float left
  margin-left 150px
  width 295px
  height 178px
  border 1px dashed #d9d9d9
  border-radius 6px
  img
    width 100%
    height 100%
.mask
  background rgba(0, 0, 0, 0) no-repeat center / contain
  // background: rgba(49,53,65,0.60) no-repeat center/contain;
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
<style>
.dialog_button {
  width: 100%;
  height: 40px;
}
.attribute {
  width: 100%;
  height: 30px;
}
.attribute p {
  width: 25%;
  float: left;
}
</style>
